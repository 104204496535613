import * as React from "react"
import "../styles/home.css"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const Events = () => {
  return (
    <Layout title={"SeaWorld and Homecoming Event | Pascendi Gregis"}>
      <div className="w-full flex flex-col md:flex-row sticky top-0 z-50">
        <a
          href="#top"
          className="w-full text-3xl text-white lg:text-5xl font-bold text-center uppercase bg-gradient-to-r from-blue-700 to-purple-600 md:py-2 hover:tracking-wide duration-300"
        >
          SEAWORLD DETAILS
        </a>
        <a
          href="#passion"
          className="w-full text-3xl lg:text-5xl text-white font-bold text-center  uppercase bg-gradient-to-r from-yellow-800 to-[#b4935f] md:py-2 hover:tracking-wide duration-300"
        >
          SANFORD DETAILS
        </a>
      </div>
      <section id="top" className="bg-[#03264f] text-blue-200">
        <div className="max-w-[1366px] mx-auto w-full">
          <StaticImage
            src="../images/seaworld-field-trip-header.png"
            alt="Seaworld Field day header"
            className="w-full h-full"
          />
        </div>
        <div className="w-10/12 max-w-[1366px] mx-auto">
          <div className="py-10 w-10/12 max-w-[800px] mx-auto leading-relaxed text-lg">
            <h2 className="text-3xl font-bold uppercase">
              Seaworld Event DETAILS
            </h2>
            <br />
            <h3 className="text-2xl font-bold uppercase text-white">
              Ticket distribution
            </h3>
            <div className="p-5">
              <StaticImage
                src="../images/field-trip-check-in.png"
                alt="Seaworld Field day header"
                className="w-full h-full"
              />
            </div>
            <ul className="list-disc w-11/12 mx-auto space-y-3">
              <li>
                The group will meet at 9:00AM at{" "}
                <span className="text-white">FIELD TRIP CHECK-IN</span> NOT THE
                MAIN ENTRANCE
              </li>
              <p>
                Please be sure to understand this part. This is NOT the main
                entrance. This is a special entrance for School Field Trips.
                With special security (to allow food) and ticketing (to go in as
                a group).
              </p>

              <li>Tickets will be distributed between 9:00AM and 9:30AM.</li>
              <li>
                If you have not arrived by 9:30AM your ticket will be picked up
                at a different location. Contact{" "}
                <a
                  className="text-white underline decoration-dotted underline-offset-4"
                  href="https://t.me/dennislindo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dennis
                </a>{" "}
                or{" "}
                <a
                  className="text-white underline decoration-dotted underline-offset-4"
                  href="https://t.me/dennislindo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Joy{" "}
                </a>{" "}
                for ticketing issues.
              </li>
              <li>
                Parking is <b>NOT</b> included and has a cost of $30 per
                vehicle.
              </li>
              <li>
                Parking and other upgrades can be purchased in advance{" "}
                <a
                  className="text-white underline decoration-dotted underline-offset-4"
                  href="https://seaworld.com/orlando/upgrades/"
                  target="_blank"
                  rel="noreferrer"
                >
                  online{" "}
                </a>
                or at the park.
              </li>
            </ul>
            <br />
            <h3 className="text-2xl font-bold uppercase text-white">
              FOOD and DRINKS
            </h3>
            <ul className="list-disc w-11/12 mx-auto space-y-3">
              <li>
                Those who purchased Meal Plans will receive a band along with
                their tickets
              </li>
              <li>If you wish to buy a Meal Plan you may do so at the Park.</li>
              <li>
                Bringing your own lunch?
                <ul>
                  <li className="font-bold">
                    - Coolers, large bags, or large boxes are{" "}
                    <span className="text-white">not permitted</span>
                  </li>
                  <li>
                    - Your lunch may be stored in your backpack, loose or in a
                    small bag, but not in a hard container (glass, plastic,
                    etc...)
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <h3 className="text-2xl font-bold uppercase text-white">
              CLOTHING: WHAT TO WEAR?
            </h3>
            <ul className="list-disc w-11/12 mx-auto space-y-3">
              <p>
                Always with modesty in mind, plan as if you were to get soaked
                in water. Seaworld is not exactly a water park but there is
                plenty of water at the facility. From Animal shows to rides,
                chances are high that you will get wet.
                <br />
                We recommend:
              </p>
              <li>Dri-fit style clothing. Something that dries quickly.</li>
              <li>
                If possible, pack an extra set of clothes for small children
              </li>
              <li>Bring a towel or a poncho to dry off and stay dry</li>
              <li>
                Bring a bag to store your shoes and keep them dry during wet
                rides.
              </li>
              <li>
                Consider using a Ziplock bag to store your phone and other
                personal items.
              </li>
            </ul>
            <br />
            <h3 className="text-2xl font-bold uppercase text-white">
              General Recommendations
            </h3>
            <ul className="list-disc w-11/12 mx-auto space-y-3">
              <li>Every student to bring a backpack</li>
              <li>
                Use the backpack to store lunch or snacks (inside of paper bags)
              </li>
              <li>Take a small towel, a bag to keep shoes dry</li>
            </ul>
          </div>
        </div>
      </section>
      <div className="w-full bg-gradient-to-t from-[#b4935f] to-[#03264f] py-32 lg:py-48"></div>
      <section
        id="passion"
        className="w-full bg-gradient-to-b from-[#b4935f] to-yellow-800 "
      >
        <div className="max-w-[1366px] mx-auto w-full pt-20">
          <div className="w-full">
            <StaticImage
              src="../images/Passion-Week-Homecoming-Header.png"
              alt="Homecoming Field Day in Sanford, Florida"
              className="w-full h-full"
            />
          </div>
          <div className="py-10 w-10/12 max-w-[800px] mx-auto leading-relaxed text-lg text-white pb-20">
            <h2 className="text-2xl font-bold uppercase">
              Homecoming Event DETAILS
            </h2>
            <h2 className="text-xl font-bold uppercase text-yellow-300">
              SCHEDULE
            </h2>
            <div className="grid grid-cols-[auto_1fr] gap-3 text-xl">
              <div className="text-right ">10:00AM</div>
              <div className="w-3/4 ">
                - Stations of the Cross / Confessions
              </div>
              <div className="text-right ">11:00AM</div>
              <div className="w-3/4">- Mass / Confessions</div>
              <div className="text-right ">12:30PM</div>
              <div className="w-3/4">- Catechism for all</div>
              <div className="text-right ">1:00PM</div>
              <div className="w-3/4">- Lunch for all</div>
              <div className="text-right ">2:00PM</div>
              <div className="w-3/4">- Games by teams / Parents Conference</div>
              <div className="text-right ">3:30PM</div>
              <div className="w-3/4">- Snacks / General Games</div>
              <div className="text-right ">4:30PM</div>
              <div className="w-3/4">- Award Ceremony</div>
              <div className="text-right ">5:00PM</div>
              <div className="w-3/4">- Rosary</div>
              <div className="text-right ">6:00PM</div>
              <div className="w-3/4">- Campfire with the Cadets</div>
              <div className="text-right ">7:00PM</div>
              <div className="w-3/4">- End</div>
            </div>

            <br />
            <p className="bg-yellow-500 px-4 py-1 text-center text-yellow-900 shadow-xl">
              For additional questions and information, you may contact us at{" "}
              <a href="mailto:pascendigregis@gmail.com">
                pascendigregis@gmail.com
              </a>
            </p>
          </div>
          <div className="w-full py-40 flex items-center justify-center flex-col gap-5">
            <p className="max-w-[800px] w-10/12 text-lg text-white text-center">
              Want to help or contribute to the event? You may donate to help us
              alleviate some of the cost of our Homecoming Event for our Florida
              Families. Thank you for your support!
            </p>
            <a
              href="https://donate.stripe.com/eVa3fV3Q8fVn6ukdRa"
              target="_blank"
              rel="noreferrer"
              className="max-w-max text-4xl font-bold text-center border-4 text-white rounded-xl hover:bg-red-800 hover:-translate-y-1 hover:shadow-xl duration-200 ease-in-out px-6 py-2 uppercase"
            >
              Donate
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Events
